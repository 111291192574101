import PropTypes from "prop-types"
import classNames from "classnames"
import React from "react"
import "./styles.scss"

const Row = ({ children, className, style }) => {
  const getClassNames = () => classNames("row", className)

  return <div className={getClassNames()} style={style}>{children}</div>
}

Row.defaultProps = {
  className: null,
  style: null,
}

Row.propTypes = {
  className: PropTypes.string,
  style: PropTypes.string,
}

export default Row
