import { Link } from "gatsby"
import React from "react"
import Button from "../components/Button"
import { Column, Container, Row } from "../components/Grid"
import Section from "../components/Section"
import Share from "../components/Share"
import deLogo from "../images/global/img-de-logo.png"
import amazingMeLogo from "../images/theme/logo-dove-horizontal.svg"

const footerCallout = () => (
  <Section className="subfooter">
    <Container>
      <div className={"subfooter__logo__container"}>
        <img
          src={amazingMeLogo}
          className="subfooter-image subfooter-image__dove-logo"
          alt="Title: Facts, Info etc."
        />

        <img
          src={deLogo}
          className="subfooter-image subfooter-image__de-logo"
          alt="Title: Facts, Info etc."
        />
      </div>
      <Row>
        <Column span={8}>
          <h3>About the Partnership</h3>
          <p>
            The Dove Self-Esteem Project and Discovery Education have joined
            forces to bring <em>Amazing Me</em> to students, educators and
            families nationwide. We know that students who are confident lead
            healthier, happier lives. Access our resources to help students
            realize their full potential and achieve success in school and
            beyond.
          </p>
          <Link to={"/about/"}>Learn more about the program</Link>
        </Column>
        <Column span={4} className={"footer__subscribe__container"}>
          <div className={""}>
            <Button
              className={"global__button footer-button"}
              to="https://info.discoveryeducation.com/AmazingMe.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More
            </Button>
            <Share />
            <p>{/* #PROGRAM SOCIAL */}</p>
          </div>
        </Column>
      </Row>
    </Container>
  </Section>
)

export default footerCallout
