import React, { useState } from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import Icon from "../Icon"
import "./styles.scss"

const Menu = () => {
  const [subMenuOpen, setSubMenuState] = useState(false)
  const [elemSchoolMenuOpen, setElemSchoolMenuOpenState] = useState(false)

  const setSubmenu = () => setSubMenuState(state => !state)
  const setElemSchoolMenuOpen = () =>
    setElemSchoolMenuOpenState(state => !state)

  const currentPath =
    typeof window !== "undefined" ? window.location.pathname : null

  return (
    <nav>
      <ul className="primary__nav">
        <li className="nav-with-dropdown">
          <button
            className={classNames(
              elemSchoolMenuOpen ||
                (["/virtual-field-trip/", "/classroom-resources/"].includes(
                  currentPath
                ) &&
                  "active")
            )}
            onClick={() => setElemSchoolMenuOpen()}
          >
            Elementary School
            <Icon name="caratdown" inline className="nav-with-dropdown__icon" />
          </button>
          <ul
            className={classNames(
              `${elemSchoolMenuOpen ? "primary__nav__submenu--open" : ""}`,
              "primary__nav__submenu"
            )}
          >
            <li>
              <Link activeClassName="active" to="/virtual-field-trip/">
                Virtual Field Trip
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/classroom-resources/">
                Classroom Resources
              </Link>
            </li>
          </ul>
        </li>

        <li>
          <Link activeClassName="active" to="/power-of-confidence/">
            Middle and High School
          </Link>
        </li>
        <li className="nav-with-dropdown">
          <button
            className={classNames(
              subMenuOpen ||
                (["/master-class/", "/family-resources/"].includes(
                  currentPath
                ) &&
                  "active")
            )}
            onClick={() => setSubmenu()}
            //onBlur={() => setSubmenu(false)}
            //onFocus={() => setSubmenu()}
            //onMouseEnter={() => setSubmenu(true)}
          >
            Additional Resources
            <Icon name="caratdown" inline className="nav-with-dropdown__icon" />
          </button>
          <ul
            //onMouseLeave={() => setSubmenu(false)}
            //onFocus={() => setSubmenu(true)}
            className={classNames(
              `${subMenuOpen ? "primary__nav__submenu--open" : ""}`,
              "primary__nav__submenu"
            )}
          >
            <li>
              <Link activeClassName="active" to="/master-class/">
                Masterclasses
              </Link>
            </li>
            <li>
              <Link activeClassName="active" to="/family-resources/">
                Family Resources
              </Link>
            </li>
          </ul>
        </li>
        <li>
          <Link activeClassName="active" to="/about/">
            About
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Menu
